:root {
  --black: #272727;
  --green: #14a76c;
  --yellow: #fff400;
  --gray: #747474;
  --red: #ff652f;
  --bgc: #212121;
  --white: #ffffff;
}

/* Position and sizing of burger button */
.settings-menu .bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  left: 36px;
  top: 75px;
}

.values-menu .bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  right: 36px;
  top: 75px;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: var(--gray);
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: var(--white);
}

/* Color/shape of close button cross */
.bm-cross {
  background: var(--white);
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

/* General sidebar styles */
.bm-menu {
  background: var(--black);
  font: 18px Consolas;
  padding: 1em 1em 0;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: var(--gray);
}

/* Wrapper for item list */
.bm-item-list {
}

/* Individual item */
.bm-item {
  display: inline-block;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--white);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--gray);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--gray);
}

/* Divs */
div.centered {
  padding-top: 1em;
  text-align: center;
}

div.underlined {
  border-bottom: 1px solid var(--black);
}

/* Labels */
label {
  color: var(--white);
}

label.centered {
  padding-top: 1em;
  text-align: center;
}

label.underlined {
  border-bottom: 1px solid black;
}

label.large-print {
  font: 20px Consolas;
}

/* Text Fields */
input[type=text] {
  height: 100%;
  display: inline-block;
  border: 0;
  border-radius: 4px;
  font: 18px Consolas;
}

input[type=text]:focus{
    outline: none;
}

input[type=text].small-input {
  width: 2em;
}

input[type=text].medium-input {
  width: 4em;
}

/* Dropdowns */
select {
  height: 100%;
  display: inline-block;
  border: 0;
  border-radius: 4px;
  font: 18px Consolas;
}

/* Checkboxes */
input[type=checkbox] {
  margin-right: 1em;
}

/* Buttons */
button.wide-button {
  transition-duration: 0.3s;
  color: var(--white);
  background-color: var(--black);
  font: 20px Consolas;
  border: 0;
  border-radius: 4px;
  margin: 0 1em;
  width: 85%;
  height: 2em;
}

button.wide-button:hover {
  background-color: var(--white);
  color: var(--black);
}
