:root {
  --black: #272727;
  --green: #14a76c;
  --yellow: #fff400;
  --gray: #747474;
  --red: #ff652f;
  --bgc: #212121;
  --white: #ffffff;
}

body {
  margin: 0;
  font-family: "Fira Sans Extra Condensed", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--bgc);
}

h2 {
  font-weight: 400;
  margin-bottom: 30px;
  color: var(--gray);
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

ul {
  list-style-type: none;
}

table {
  width: 100%;
}
.container {
  max-width: 540px;
  margin: 50px auto;
  justify-content: center;
  color: var(--gray);
}

.block {
  padding: 40px;
  border-radius: 15px;
  align-items: center;
  justify-content: center;
  background-color: var(--black);
  flex: 1;
}

.small-block {
  margin-right: 10px;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0;
}

.block-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 40px;
}

.rowBlock {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.num-pad-input {
  background-color: var(--gray);
  border-radius: 15px;
  border-width: 0;
  font-size: 40px;
  color: white;
  width: 50%;
  text-align: center;
  margin: 0 auto 2em auto;
  display: block;
}
input:focus {
  outline: none;
}

.label {
  color: var(--yellow);
  font-size: 30px;
}

.button {
  width: 100%;
  background-color: var(--green);
  border-radius: 15px;
  border-width: 0;
  color: white;
  padding: 10px;
  font-size: 30px;
}

.text {
  color: GRAY;
  font-size: 50px;
  text-align: center;
}

th,
td {
  border-bottom: 1px solid #ddd;
}

.table {
  width: 100%;
  font-size: 25px;
  text-align: center;
}

.pagination {
  margin-top: 30px;
}

.yellow-text {
  color: var(--yellow);
  font-size: 50px;
  text-align: center;
}
.answersCount {
  margin-left: 16px;
  margin-bottom: 0;
}

.number {
  color: var(--gray);
  width: 75px;
  height: 75px;
  border-radius: 50%;
  border: 4px solid var(--gray);
  justify-content: center;
  align-items: center;
  display: flex;
  font-size: 30px;
  cursor: pointer;
  font-weight: 500;
  transition: color 0.3s ease;
  transition: border-color 0.3s ease;
}

.explanation {
  margin-top: 20px;
  margin-bottom: -25px;
}

.text_in_button {
  color: var(--yellow);
  font-size: 30px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.green {
  border-color: var(--green);
  color: var(--green);
}

.red {
  border-color: var(--red);
  color: var(--red);
}

.gray {
  border-color: var(--gray);
  color: var(--gray);
}

.yellow {
  border-color: var(--yellow);
  color: var(--yellow);
}

.problem-txt {
  text-align: center;
  font-size: 90px;
  color: var(--yellow);
}

.problem-txt > div > mjx-container {
  margin: 0 !important;
}

.num-pad {
  display: block;
}
.num-pad-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 10px;
  grid-row-gap: 2em;
  justify-items: center;
}

.profileContainer {
  max-width: 80%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: row;
  color: var(--gray);
}
.sideNav {
  padding: 40px;
  border-radius: 15px;
  background-color: var(--black);
  height: fit-content;
  width: 200px;
  line-height: 36px;
}
.sideNav a {
  color: var(--gray);
}

.sideNav a:hover {
  color: #f1f1f1;
  text-decoration: none;
}

/* Style page content */
.main {
  padding: 0px 20px;
  width: 100%;
  height: 100vh;
}
.divider {
  border-top: 1px solid var(--gray);
  margin: 12px 0;
}

.buttonSm {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.buttonSm.success {
  background-color: var(--green);
  color: white;
}
.buttonSm.danger {
  background-color: var(--red);
  color: white;
}
.buttonSm.primary {
  background-color: var(--primary);
  color: white;
}
.buttonSm.cancel {
  background-color: var(--gray);
  color: white;
}
.buttonSm.success:hover {
  background-color: #0e6a46;
}
.buttonSm.primary:hover {
  background-color: #0069d9;
}
.buttonSm.left-margin {
  margin-left: 20px;
}

.input {
  position: relative;
  width: 100%;
  max-width: 350px;
  border-radius: 3px;
  overflow: hidden;
}
.input .label {
  position: absolute;
  top: 20px;
  left: 12px;
  font-size: 16px;
  color: black;
  font-weight: 500;
  transform-origin: 0 0;
  transform: translate3d(0, 0, 0);
  transition: all 0.2s ease;
  pointer-events: none;
}
.input .focus-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.05);
  z-index: -1;
  transform: scaleX(0);
  transform-origin: left;
}
.input input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 100%;
  border: 0;
  font-family: inherit;
  padding: 16px 12px 0 12px;
  height: 56px;
  font-size: 18px;
  line-height: 24px;
  font-weight: 400;
  background: var(--gray);
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.3);
  color: black;
  transition: all 0.15s ease;
}
.input textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 100%;
  border: 0;
  font-family: inherit;
  padding: 26px 12px 0 12px;
  font-size: 18px;
  line-height: 24px;
  font-weight: 400;
  background: var(--gray);
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.3);
  color: black;
  transition: all 0.15s ease;
  border-radius: 3px;
}
.input input:hover {
  background: white;
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.5);
}
.input textarea:hover {
  background: white;
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.5);
}
.input input:not(:-moz-placeholder-shown) + .label {
  color: rgba(0, 0, 0, 0.5);
  transform: translate3d(0, -12px, 0) scale(0.75);
}
.input textarea:not(:-moz-placeholder-shown) + .label {
  color: rgba(0, 0, 0, 0.5);
  transform: translate3d(0, -12px, 0) scale(0.75);
}
.input input:not(:-ms-input-placeholder) + .label {
  color: rgba(0, 0, 0, 0.5);
  transform: translate3d(0, -12px, 0) scale(0.75);
}
.input textarea:not(:-ms-input-placeholder) + .label {
  color: rgba(0, 0, 0, 0.5);
  transform: translate3d(0, -12px, 0) scale(0.75);
}
.input input:not(:placeholder-shown) + .label {
  color: rgba(0, 0, 0, 0.5);
  transform: translate3d(0, -12px, 0) scale(0.75);
}
.input textarea:not(:placeholder-shown) + .label {
  color: rgba(0, 0, 0, 0.5);
  transform: translate3d(0, -12px, 0) scale(0.75);
}
.input input:focus {
  background: white;
  outline: none;
  box-shadow: inset 0 -2px 0 var(--green);
}
.input textarea:focus {
  background: white;
  outline: none;
  box-shadow: inset 0 -2px 0 var(--green);
}
.input input:focus + .label {
  color: black;
  transform: translate3d(0, -12px, 0) scale(0.75);
}
.input textarea:focus + .label {
  color: black;
  transform: translate3d(0, -12px, 0) scale(0.75);
}
.input input:focus + .label + .focus-bg {
  transform: scaleX(1);
  transition: all 0.1s ease;
}
.input textarea:focus + .label + .focus-bg {
  transform: scaleX(1);
  transition: all 0.1s ease;
}

.joinByLinkContainer > h2 > strong {
  color: var(--yellow);
}

.groupRow > td > h5 {
  margin-top: 10px;
}

.groupRow > td:first-child {
  padding-left: 10px;
}

.groupRow:hover {
  background-color: #212121;
}

.groupRow > td > p.link {
  color: var(--blue);
  font-size: 11px;
}
.groupRow > td > p > .buttonSm {
  padding: 0.175rem 0.75rem;
  font-size: 0.75rem;
  line-height: 1.25;
  border-radius: 0.25rem;
  margin-left: 10px;
}
.groupRow > td > p > .buttonSm:focus {
  outline: 0 !important;
}
.pinCode > strong,
.schoolName > strong {
  color: var(--yellow);
}
@media (max-width: 480px) {
  h2 {
    font-size: 28px;
    font-weight: 300;
  }
  .container {
    max-width: 80% !important;
    margin-top: 40px !important;
  }

  .number {
    border-width: 2px;
    width: 50px;
    height: 50px;
    font-size: 20px;
  }
  .block-row {
    margin-bottom: 30px !important;
  }
  .block {
    padding: 25px !important;
  }
  .num-pad-container {
    grid-template-columns: 1fr 1fr 1fr;
    grid-row-gap: 2em;
  }
  .small-bottom {
    margin-bottom: 10px;
  }
  .problem-txt {
    font-size: 70px;
    line-height: 80px;
  }
  .num-pad-input {
    margin-bottom: 30px;
  }
}

.circle {
  background-color: var(--yellow);
  width: 750px;
  height: 750px;
  position: fixed;
  margin-left: 45%;
  margin-top: -230px;
  border-radius: 50%;
  z-index: -1;
}

.circle2 {
  background-color: var(--yellow);
  width: 300px;
  height: 300px;
  position: fixed;
  margin-left: -40%;
  margin-top: 30%;
  border-radius: 50%;
  z-index: -1;
}
